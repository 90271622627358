.abox {
    max-width: 1200px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 0 0 48px 0;
    border-radius: 4px;
}

.aboxBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.aboxIntroContent {
    display: grid;
    grid-gap: 48px;
    padding: 48px;
    grid-template-columns: repeat(2, 1fr);
}

.aboxContainerHeader {
    grid-template-columns: repeat(1, 1fr);
}