@import url('./layout.css');
@import url('./nangu.css');

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: #008cfe;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-root {
  height: auto !important;
}

.MuiDialogTitle-root {
  background: #ff6f01 !important;
  color: #fff !important;
}

.MuiSvgIcon-root {
  color: #fff !important;
}

.MuiList-root {
  background: #fff !important;
}

.MuiListItem-root {
  font-weight: bold;
}