@font-face {
    font-family: 'work_sansregular';
    src: url('./fonts/worksans-medium-webfont.woff2') format('woff2'),
    url('./fonts/worksans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lietz_lindauhamburgregular';
    src: url('./fonts/lietzlindauhamburg-webfont.woff2') format('woff2'),
    url('./fonts/lietzlindauhamburg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
    box-sizing: border-box;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.body {
    opacity: 0;
    transition: opacity 2s;
}

.header {
    padding: 144px 0 288px 0;
    position: relative;
    z-index: 2;
}

.nav {
    background: #008cfe;
    color: #fff;
    padding: 24px;
    position: fixed;
    z-index: 3;
    width: 100%;
}

.textCenter {
    text-align: center;
}

.nav a {
    color: #fff;
}

.nav.scroll {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.nav ul {
    max-width: 1200px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    display: grid;
    grid-gap: 24px;
    grid-auto-flow: column;
    justify-content: flex-end;
    font-size: 18px;
    align-items: center;
}

.nav ul li {
    text-decoration: underline;
}

.grid {
    display: grid;
    grid-gap: 24px;
    grid-auto-rows: min-content;
}

.lowercase {
    text-transform: lowercase;
}

.main {
    position: relative;
    z-index: 2;
    background-repeat: repeat;
    background-position: 0 90px;
    /* background-color: #b6cf52; */
}

.logo {
    margin-bottom: 72px;
    width: 450px;
}

@media (max-width: 1200px) {
    .logo {
        width: 275px;
    }
}

.content {
    font-family: source-serif-pro, serif;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
    color: #fff;
    text-align: center;
    position: relative;
}

.boxLayer {
    position: relative;
    z-index: 2;
}

.boxContainer {
    display: grid;
    grid-gap: 48px;
    padding: 48px;
    grid-template-columns: repeat(2, 1fr);
}

.boxContainerHeader {
    grid-column-start: 1;
    grid-column-end: 3;
}

.boxContainerFooter {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: right;
}

.box {
    max-width: 1200px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 0 0 48px 0;
    border-radius: 4px;
    overflow: hidden;
}

.abox:first-child {
    margin-top: -192px;
}

@media (max-width: 1200px) {
    .abox:first-child {
        margin-top: -240px;
    }
}

@media (max-width: 1200px) {
    .boxContainer {
        padding: 24px;
    }

    .boxContainer {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
    .boxContainerHeader {
        grid-column-end: 2;
    }

    .boxContainerFooter {
        grid-column-end: 2;
    }

    .box {
        margin-bottom: 48px;
    }
}

.boxHeader {
    margin: 0;
}

.headerContent {
    margin: 0 auto 0px auto;
}

.landscape {
    position: absolute;
    width: 100%;
    top: 648px;
    bottom: 0;
    z-index: 1;
    background: #008cfe;
    overflow: hidden;
}

.landscapeBack {
    width: 100%;
    position: absolute;
    top: 584px;
    bottom: 0;
    background: #1a721a;
    background-size: auto 100%;
}

.landscapeBack:before {
    content: "";
    position: absolute;
    height: 484px;
    width: 100%;
    top: -484px;
    background: url("images/landscape-bg-dark.svg");
    background-size: cover;
    background-position: left top;
}

.landscapeBack:after {
    content: "";
    position: absolute;
    height: 484px;
    width: 100%;
    top: -484px;
    background-image: url("images/bg.png");
    mask: url("images/landscape-bg-dark.svg");
    mask-size: cover;
    background-repeat: repeat;
}

.landscapeCenter {
    width: 100%;
    position: absolute;
    top: 584px;
    bottom: 0;
    background: #9cad4a;
    background-image: url("images/bg.png");
    background-repeat: repeat;
}

.landscapeCenter .imgPlant {
    position: absolute;
    right: 0;
    top: 0;
}

.landscapeCenter .imgTapir {
    position: absolute;
    right: 100px;
    top: -570px;
}

.landscapeCenter:before {
    content: "";
    position: absolute;
    height: 484px;
    width: 100%;
    top: -484px;
    background: url("images/landscape-bg-medium.svg");
    background-size: cover;
    background-position: right top;
}

.landscapeCenter:after {
    content: "";
    position: absolute;
    height: 484px;
    width: 100%;
    top: -484px;
    background-image: url("images/bg.png");
    mask: url("images/landscape-bg-medium.svg");
    mask-size: cover;
    background-repeat: repeat;
}

.landscapeFront {
    width: 100%;
    position: absolute;
    top: 1670px;
    bottom: 0;
    background: #b6cf52;
    background-image: url("images/bg.png");
    background-repeat: repeat;
}

.landscapeFront .imgPlant {
    position: absolute;
    left: 0;
    top: -200px;
}

.landscapeFront .imgPlantTwo {
    position: absolute;
    left: 50px;
    top: -945px;
}

.landscapeFront:before {
    content: "";
    position: absolute;
    height: 620px;
    width: 100%;
    top: -620px;
    background: url("images/landscape-bg-light.svg");
    background-size: cover;
    background-position: left top;
}

.landscapeFront:after {
    content: "";
    position: absolute;
    height: 620px;
    width: 100%;
    top: -620px;
    background-image: url("images/bg.png");
    mask: url("images/landscape-bg-light.svg");
    mask-size: cover;
    background-repeat: repeat;
}

.sun {
    background: url("images/sun.svg");
    width: 233px;
    height: 233px;
    position: absolute;
    right: -120px;
    top: 250px;
}

@media (max-width: 1200px) {
    .sun {
        display: none;
    }
}

.swallow {
    background: url("images/swallow.svg");
    width: 80px;
    height: 80px;
    position: absolute;
    right: 60px;
    top: 60px;
}

.h1 {
    font-family: 'lietz_lindauhamburgregular', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 88px;
    line-height: 84px;
    text-align: center;
    text-transform: uppercase;
    margin: 0px 0 96px 0;
}

@media (max-width: 1200px) {
    .h1 {
        font-size: 64px;
        line-height: 13vw;
        margin: 0px 0 48px 0;
    }
}

.h2 {
    font-family: 'lietz_lindauhamburgregular', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 57px;
    text-align: center;
    text-transform: uppercase;
    margin: 24px 0;
}

@media (max-width: 1200px) {
    .h2 {
        font-size: 48px;
        line-height: 46px;
    }
}

.h3 {
    font-family: 'lietz_lindauhamburgregular', sans-serif;
    text-transform: uppercase;
}

@media (max-width: 1200px) {
    .h3 {
        font-size: 36px;
        line-height: 34px;
    }
}

.h4 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 36px;
}

.h4:last-child {
    margin-bottom: 0;
}

p:last-child {
    margin-bottom: 0
}

@media (max-width: 1200px) {
    .h4 {
        font-size: 36px;
        line-height: 34px;
    }
}

.h5 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 28px;
}

.h5:last-child {
    margin-bottom: 0
}

@media (max-width: 1200px) {
    .h5 {
        font-size: 28px;
        line-height: 26px;
    }
}

.p {
    font-family: 'work_sansregular', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
}

.largeBreak {
    display: block;
}

@media (max-width: 1200px) {
    .largeScreenNewLine {
        display: none;
    }
}

.social img {
    height: 30px;
    margin: 10px 20px 50px 20px;
    color: #9f4180;
}

small.boxText {
    font-size: 16px;
    opacity: 0.8;
}

.boxImage img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border: 6px solid rgba(255, 255, 255, 0.7);
    width: 100%;
    border-radius: 4px;
}

.boxImage.noBorder img {
    border: none;
}

@media (max-width: 1200px) {
    .box .boxImage img {
        margin: 0;
    }
}
.readMore {
    cursor: pointer;
    margin-bottom: 0;
}

.svgIcons {
    vertical-align: middle;
}

.abox {
    max-width: 1200px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 0 0 48px 0;
    border-radius: 4px;
    position: relative;

    font-family: 'work_sansregular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;

    text-align: left;
}

.aboxBackground {
    position: absolute;
    border-radius: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.aboxIntroContent,
.aboxMainContent {
    display: grid;
    grid-gap: 48px;
    padding: 48px;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
}

.aboxMainContent {
    display: none;
}

.aboxMainContent > div {
    z-index: 2;
}

.aboxMainContentVisible .aboxMainContent {
    display: grid;
}

.aboxMainContentVisible .readMore {
    visibility: hidden;
}

.abox h3 {
    font-family: 'lietz_lindauhamburgregular', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
    font-size: 42px;
    line-height: 40px;
}

.abox img {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border: 6px solid rgba(255, 255, 255, 0.7);
    width: 100%;
    border-radius: 4px;
}

.abox small {
    font-size: 16px;
    opacity: 0.8;
    display: block;
    text-align: center;
}

.abox .verticalCenter {
    display: flex;
    align-items: center;
}

.abox .horizontalCenter {
    justify-content: center;
}

.abox .readLess {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: right;
}

.abox .readLess span {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .aboxIntroContent,
    .aboxMainContent {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        padding: 24px;
    }

    .abox .readLess {
        grid-column-end: 2;
    }

    .introImage {
        order: -1;
    }

    .abox h3 {
        font-size: 32px;
        line-height: 34px;
    }
}

/* Mint */
.mint .aboxBackground {
    background: #fff;
}

.mint h3 {
    color: #999;
}

.mint {
    color: #999;
}

/* Blueprint */
.blueprint .aboxBackground {
    background: #ffd702;
}

.blueprint h3 {
    color: #da2e02;
}

.blueprint {
    color: #7d4c1a;
}

.blueprint .aboxMainContent {
    background-image: url("images/monkey-two.svg");
    background-repeat: no-repeat;
    background-position: bottom 20px left 20px;
}

.blueprint img {
    /*max-width: 400px;*/
}

/* NFTrees */
.nftrees .aboxBackground {
    background-color: #724964;
    background-image: url("images/nftrees-bg.svg");
    background-repeat: no-repeat;
}

.nftrees h3 {
    color: #7bcaff;
}

.nftrees {
    color: #fff;
}

.nftrees .introImage img {
    border: none;
    height: 340px;
    width: auto;
}

.nftrees img {
    width: 200px;
}

.nftrees .aboxIntroContent {
    grid-template-columns: repeat(4, 1fr);
}

.nftrees .boxContainerHeader {
    grid-column-start: 1;
    grid-column-end: 5;
}

.nftrees ul {
    margin: 0;
    padding: 0;
    font-size: 20px;
}

.nftrees li {
    margin: 0;
    padding: 0;

}

@media (max-width: 1200px) {
    .roadmap .plant {
        display: none;
    }

    .nftrees .aboxBackground {
        background-position: 0 -30px;
    }

    .nftrees .aboxIntroContent {
        grid-template-columns: repeat(2, 1fr);
    }

    .nftrees .boxContainerHeader {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

/* Revolution */
.revolution .aboxBackground {
    background-color: #ff6f01;
}

.revolution h3 {
    color: #ffd702;
}

.revolution {
    color: #fff;
}

/* Roadmap */
.roadmap .aboxBackground {
    background-color: #b72702;
    background-image: url("images/roadmap-bg.svg");
    background-repeat: no-repeat;
}

.roadmap h3 {
    color: #b6cf52;
}

.roadmap {
    color: #fff;
}

.roadmap .introImage img {
    border: none;
    min-height: 390px;
}

.roadmap .aboxBackgroundMainContent {
    background-image: url("images/roadmap-path.svg");
    background-size: auto 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
}

.roadmap .plant {
    width: 275px;
    position: absolute;
    bottom: 5px;
    right: 200px;
}

.roadmap .plant img {
    border: none;
}

.roadmap .contrastHeader {
    background: #E3603F;
    margin-left: -48px;
    margin-right: -48px;
    padding: 48px;

    display: grid;
    grid-gap: 48px;
    padding: 48px;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1200px) {
    .roadmap .plant {
        display: none;
    }

    .roadmap .aboxBackground {
        background-position: 0 -70px;
    }

    .roadmap .contrastHeader {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        padding: 24px;
        margin-left: -24px;
        margin-right: -24px;
    }
}
